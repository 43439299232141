import React from 'react'
import './Chargement.scss'

const Chargement = () => {
  return (
    <div className='Chargement'>
      <div className='Roue' />
      <div className='Loading'> Chargement... </div>

    </div>
  )
}

export default Chargement